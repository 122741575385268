<template>
  <div class="contents userMgt">
    <div class="title flexB">
      <h2>기프트 주문 관리</h2>
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">검색조건</p>
        <select v-model="inputMode">
          <option value="" selected disabled>선택</option>
          <option value="status">상태</option>
          <option value="item">기프트</option>
        </select>
        <input type="text" v-model="searchWord" @keyup.enter="search" />
      </div>

      <div class="buttonWrap">
        <button class="basic" @click="search">검색</button>
        <button class="line" @click="reset">초기화</button>
      </div>
    </div>

    <div class="box one collection">
      <h2 class="bold">기프트 주문 정보</h2>
      <table>
        <colgroup>
          <col style="width: 5%" />
          <col style="width: 8%" />
          <col style="width: 13%" />
          <col style="width: 20%" />
          <col style="width: 15%" />
          <col style="width: 10%" />
        </colgroup>
        <tr>
          <th>번호</th>
          <th>고객명</th>
          <th>타입</th>
          <th>기프트</th>
          <th>주문날짜</th>
          <th class="center">발송처리</th>
        </tr>
        <tr v-for="(data, i) in orderList" :key="i">
          <td>
            {{ total + 1 - (i + 1 + (currentPage - 1) * 10) }}
          </td>
          <td>
            {{ data.user?.username }}
          </td>
          <td>
            {{ data.gift.title }}
          </td>
          <td>
            {{ data.selectedItem }}
          </td>

          <td>{{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}</td>
          <td class="center">
            <span v-if="data.isReceive == true">발송완료</span>
            <button v-else @click="submit(data._id)">발송확인</button>
          </td>
        </tr>
      </table>
      <NoData v-if="isNodata" />
      <div class="pagination" v-if="orderList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { fetchOrderList, updateOrder } from "@/api/admin";
import NoData from "@/components/contents/Nodata";
import { format } from "@/mixins/format";
export default {
  mixins: [format],
  components: { NoData },
  data() {
    return {
      moment: moment,
      category: "",
      orderList: [],
      inputMode: "",
      searchWord: "",
      total: 0,
      currentPage: 1,
      isNodata: false,
    };
  },
  computed: {
    ...mapState(["navbarState"]),
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "13-2");
  },
  mounted() {
    this.getOrderList();
  },
  methods: {
    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.category = "";
      this.currentPage = 1;
      this.orderList = [];
      this.total = 0;
      this.getOrderList();
    },
    search() {
      if (this.searchWord && this.inputMode == "") {
        this.$alert("검색 조건을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      this.getOrderList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getOrderList();
    },

    getOrderList() {
      let params = {
        page: this.currentPage,
        searchWord: this.searchWord,
        inputMode: this.inputMode,
        tourType: this.tourType,
      };
      fetchOrderList(params).then((res) => {
        if (res.data.status == 200) {
          this.orderList = res.data.data;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    submit(id) {
      let data = {
        orderId: id,
      };

      updateOrder(data).then((res) => {
        if (res.data.status == 200) {
          this.$alert("주문 발송 정보가 반영 되었습니다.", {
            confirmButtonText: "확인",
            center: true,
          });
          this.getOrderList();
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
